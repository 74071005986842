<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <div class="text-center">
        <h1 class="text-h3 font-weight-bold white--text">{{ $t('helloScreen.welcome') }}</h1>
        <p class="white--text">{{ $t('helloScreen.joinInAdmin') }}</p>
      </div>
      <v-card
        class="pa-12 rounded-xl"
      >
        <h2 class="text-h4 font-weight-bold">{{ $t('helloScreen.welcomeBack') }}</h2>
        <p>{{ $t('helloScreen.loginAndPass') }}</p>
        <v-form>
          <label class="login-page__label" for="login">{{ $t('helloScreen.login') }}</label>
          <v-text-field
            v-model="username"
            name="login"
            :placeholder="$t('helloScreen.login')"
            type="text"
            color="deep-purple accent-2"
            outlined
          ></v-text-field>
          <label class="login-page__label" for="password">{{ $t('helloScreen.password') }}</label>
          <v-text-field
            v-model="password"
            name="password"
            :placeholder="$t('helloScreen.password')"
            type="password"
            id="password"
            color="deep-purple accent-2"
            outlined
          ></v-text-field>
          <label v-if="isStage" class="login-page__label" for="password">{{ $t('helloScreen.instance') }}</label>
          <v-select
              contentEditable="true"
              v-if="isStage"
              item-text="name"
              item-value="value"
              v-model="instance"
              :items="instances"
              placeholder="instance"
              @change="setInstance"
              outlined
              id="instance"
          />
          <v-btn
            @click.prevent="handleSubmit"
            color="deep-purple accent-2"
            dark
            type="submit"
            block
          >{{ $t('helloScreen.signIn') }}</v-btn>
        </v-form>
      </v-card>
    </v-flex>
    <v-select
      class="login-page__select-lang"
      placeholder="lang"
      :items="languages"
      item-text="name"
      item-value="value"
      v-model="language"
      variant="outlined"
      @change="setLang"
    />
  </v-layout>
</template>

<script>
import systemLang from '../mixins/systemLang'

export default {
  name: "LoginPage",
  props: {
    source: String
  },
  mixins: [systemLang],
  data: () => ({
    username: "",
    password: "",
    instance: "india",
    instances: [
      {
        name: "India",
        value: "india",
      },
      {
        name: "Mexico",
        value: "mexico",
      },
      {
        name: "Pakistan",
        value: "pakistan",
      },
      {
        name: "demo BPMS",
        value: "demo",
      }
    ],
    remember: false,
    workflowData: null
  }),
  created() {
    localStorage.setItem('admin-api-url', 'india');
  },
  computed: {
    isStage() {
      return this.$store.getters["app/isStage"];
    }
  },
  methods: {
    handleSubmit () {
      if (this.username && this.password) {
        this.$store.dispatch("authentication/login", {
          login: this.username,
          password: this.password
        });
      }
    },
    setInstance() {
      if (this.isStage) {
        localStorage.setItem('admin-api-url', this.instance);
      }
    }
  },
};
</script>

<style scoped lang="scss">
.login-page {
  &__label {
    display: block;
    font-size: 14px;
    margin-bottom: 8px;
  }
  &__select-lang{
    max-width: 80px; 
    position: absolute; 
    top: 5px; 
    right: 20px;
  }
}
</style>
